import { noop } from '@bank131/utils';
import * as React from 'react';

import type { NotificationContextType } from './types';

export const NotificationContext = React.createContext<NotificationContextType>(
  {
    error: () => '',
    close: noop,
    info: () => '',
    notifications: [],
    success: () => '',
  },
);
