import * as React from 'react';

import { Root } from './ui';

export interface ButtonGhostProps
  extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  startIcon?: JSX.Element;
  variant?: 'black' | 'gray';
}

export const ButtonGhost = (props: ButtonGhostProps): JSX.Element => {
  const { children, startIcon, variant = 'black', ...restProps } = props;

  return (
    <Root {...restProps} variant={variant}>
      {startIcon}
      {children}
    </Root>
  );
};
