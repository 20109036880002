import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

import { Alert } from '~/components/Alert';
import { breakpoint } from '~/utils/breakpoint';

export const Root = styled('div')`
  max-width: 100%;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 10000;
  padding: 8px;
  box-sizing: border-box;
  overflow: hidden;
  pointer-events: none;

  ${breakpoint.to(700)} {
    padding: 4px;
  }
`;

const slide = keyframes`
  0% {
    opacity: 0;
    transform: translateX(50%);
  }

  100% {
    opasity: 1;
    transform: translateX(0);
  }
`;

export const Notification = styled(Alert)`
  max-width: calc(100% - 16px);
  min-width: 360px;
  margin-bottom: 8px;
  animation-name: ${slide};
  animation-duration: 0.15s;
  animation-timing-function: ease-out;
  pointer-events: auto;

  ${breakpoint.to(700)} {
    max-width: calc(100% - 8px);
  }
`;
