import { Root, Spin } from './ui';

export interface SpinnerProps {
  className?: string;
}

export const Spinner = (props: SpinnerProps): JSX.Element => {
  const { className } = props;

  return (
    <Root aria-label="Loading process" className={className} role="img">
      <Spin />
    </Root>
  );
};
