import styled from '@emotion/styled';

import type { ButtonGhostProps } from './ButtonGhost';

type RootProps = Required<Pick<ButtonGhostProps, 'variant'>>;

export const Root = styled('a')<RootProps>`
  display: inline-flex;
  padding: 12px;
  background-color: transparent;
  border-radius: 8px;
  color: ${(props) => {
    return {
      black: props.theme.palette.brand.black,
      gray: props.theme.palette.contrast.gray1000,
    }[props.variant];
  }};
  ${(props) => props.theme.typography.caption1};
  text-decoration: none;
  align-items: center;
  transition-duration: 0.15s;
  transition-property: color, background-color;
  transition-timing-function: ease-in-out;

  &:hover {
    background-color: ${(props) => props.theme.palette.contrast.gray200};
  }

  &:active {
    background-color: ${(props) => {
      return {
        black: props.theme.palette.contrast.gray300,
        gray: props.theme.palette.contrast.gray400,
      }[props.variant];
    }};
  }

  &:disabled {
    background-color: transparent;
    color: ${(props) => props.theme.palette.contrast.gray500};
  }
`;
