import { Global, ThemeProvider as BaseThemeProvider } from '@emotion/react';
import * as React from 'react';

import './emotion.d.ts';
import { theme } from './theme';
import { globalStyles } from './ui';

interface ThemeProviderProps {
  children: React.ReactNode;
}

export const ThemeProvider = (props: ThemeProviderProps): JSX.Element => {
  const { children } = props;

  return (
    <BaseThemeProvider theme={theme}>
      <Global styles={globalStyles} />

      {children}
    </BaseThemeProvider>
  );
};
