import { stringifyUrl } from '@bank131/utils';
import spacetime from 'spacetime';

export const ROUTE = '/dashboard';

export const initialFilters = {
  createdAtFrom: spacetime.now().subtract(1, 'week').format('iso-short'),
};

export const DEFAULT_URL = stringifyUrl({
  pathname: ROUTE,
  query: {
    filters: initialFilters,
  },
});
