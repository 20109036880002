import { nanoid } from 'nanoid';
import * as R from 'rambda';
import * as React from 'react';

import { NotificationContext } from './NotificationContext';
import { NotificationsList } from './NotificationsList';
import type { AbstractPush, NotificationType } from './types';

import { Markdown } from '~/components/Markdown';

const INITIAL_NOTIFICATIONS: NotificationType[] = [];

const $root = document.body;

interface NotificationProviderProps {
  children: React.ReactNode;
}

export const NotificationProvider = (
  props: NotificationProviderProps,
): JSX.Element => {
  const { children } = props;

  const [notifications, setNotifications] = React.useState<NotificationType[]>(
    INITIAL_NOTIFICATIONS,
  );

  const close = React.useCallback((id: NotificationType['id']) => {
    setNotifications(R.filter((notification) => notification.id !== id));
  }, []);

  const push = React.useCallback(
    (
      payload: Pick<NotificationType, 'content' | 'variant'> & {
        id?: NotificationType['id'];
      },
    ) => {
      if (payload.id) {
        close(payload.id);
      }

      const id = payload.id ?? nanoid();

      setTimeout(() => {
        setNotifications(
          R.append({
            ...payload,
            content:
              typeof payload.content === 'string' ? (
                <Markdown>{payload.content}</Markdown>
              ) : (
                payload.content
              ),
            id,
          }),
        );
      }, 0);

      return id;
    },
    [close],
  );

  const error = React.useCallback<AbstractPush>(
    (content, options = {}) =>
      push({ content, id: options.id, variant: 'error' }),
    [push],
  );

  const info = React.useCallback<AbstractPush>(
    (content, options = {}) =>
      push({ content, id: options.id, variant: 'info' }),
    [push],
  );

  const success = React.useCallback<AbstractPush>(
    (content, options = {}) =>
      push({ content, id: options.id, variant: 'success' }),
    [push],
  );

  return (
    <NotificationContext.Provider
      value={{
        close,
        error,
        info,
        notifications,
        success,
      }}
    >
      <NotificationsList close={close} list={notifications} root={$root} />

      {children}
    </NotificationContext.Provider>
  );
};
