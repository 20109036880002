import styled from '@emotion/styled';

import type { AlertProps } from './Alert';
import { ReactComponent as CircleBaseIcon } from './images/circle.svg';
import { ReactComponent as CloseBaseIcon } from './images/close.svg';

import { ButtonBase } from '~/components/ButtonBase';
import { breakpoint } from '~/utils/breakpoint';
import { omitPropsFromHTML } from '~/utils/omitPropsFromHTML';

interface RootProps {
  isClosable: boolean;
  variant: AlertProps['variant'];
}

export const Root = styled('div', {
  shouldForwardProp: omitPropsFromHTML(['isClosable', 'variant']),
})<RootProps>`
  display: flex;
  min-height: 52px;
  position: relative;
  padding: 0;
  padding-right: ${(props) => (props.isClosable ? '52px' : '24px')};
  padding-left: 52px;
  border-radius: 8px;
  box-sizing: border-box;
  ${(props) => props.theme.typography.caption1};
  align-items: center;

  ${(props) => {
    if (props.variant === 'error') {
      return `
        background-color: ${props.theme.palette.system.alertRed};
        box-shadow: inset 0 0 0 0.5px rgba(83, 11, 9, 0.25);
        color: ${props.theme.palette.brand.white};
      `;
    }

    if (props.variant === 'info') {
      return `
        background-color: ${props.theme.palette.additional.pastelYellow};
        box-shadow: inset 0 0 0 0.5px rgba(90, 77, 12, 0.15);
        color: ${props.theme.palette.brand.black};
      `;
    }

    if (props.variant === 'success') {
      return `
        background-color: ${props.theme.palette.additional.pastelMint};
        box-shadow: inset 0 0 0 0.5px rgba(10, 65, 15, 0.15);
        color: #0a410f;
      `;
    }

    return '';
  }};

  ${breakpoint.to(700)} {
    min-height: 48px;
    padding-right: ${(props) => (props.isClosable ? '36px' : '12px')};
    padding-left: 36px;
  }
`;

export const Icon = styled('span')`
  position: absolute;
  top: 16px;
  left: 24px;

  ${breakpoint.to(700)} {
    top: 12px;
    left: 12px;
  }
`;

export const CloseButton = styled(ButtonBase)`
  position: absolute;
  top: 16px;
  right: 24px;
  padding: 4px;
  margin: -4px;

  ${breakpoint.to(700)} {
    top: 12px;
    right: 12px;
  }
`;

interface CircleIconProps {
  isHovered: boolean;
}

export const CircleIcon = styled(CircleBaseIcon, {
  shouldForwardProp: omitPropsFromHTML(['isHovered']),
})<CircleIconProps>`
  display: block;
  opacity: ${(props) => (props.isHovered ? 1 : 0.15)};
  transition-duration: 0.15s;
  transition-property: opacity;
  transition-timing-function: ease-in-out;
`;

export const CloseIcon = styled(CloseBaseIcon)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const Content = styled('div')`
  margin: 18px 0 16px;

  ${breakpoint.to(700)} {
    margin: 14px 0 12px;
  }
`;
