import { noop } from '@bank131/utils';
import * as React from 'react';

import type { UserContext } from './types';

export const userContext = React.createContext<UserContext>({
  actions: {
    resetError: noop,
    signIn: () => new Promise(noop),
    signOut: () => new Promise(noop),
  },
  error: null,
  isInitialized: false,
  user: null,
});
