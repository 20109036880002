import type { Token } from '../../types';

import { request } from '~/utils/request';

type CheckTokenMethod = (token: Token) => Promise<boolean>;

/**
 * Проверяет актуальность JWT-токена.
 */
export const checkToken: CheckTokenMethod = async (token) => {
  const {
    data: { isValid },
  } = await request.get<{ isValid: boolean }>(`/token/check/${token}`);

  return isValid;
};
