export const RESOLUTIONS = [400, 500, 700, 1000] as const;

type BreakpointSwitcher = Record<
  'from' | 'to',
  (resolution: (typeof RESOLUTIONS)[number]) => string
>;

export const breakpoint: BreakpointSwitcher = {
  from: (resolution) => `@media (min-width: ${resolution}px)`,
  to: (resolution) => `@media (max-width: ${resolution}px)`,
};
