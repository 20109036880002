import * as React from 'react';

import { CircleIcon, CloseButton, CloseIcon, Content, Icon, Root } from './ui';

import { Markdown } from '~/components/Markdown';
import { ReactComponent as CheckCircledIcon } from '~/ui/icons/check-circled.svg';
import { ReactComponent as InfoCircledIcon } from '~/ui/icons/info-circled.svg';
import { ReactComponent as WarningIcon } from '~/ui/icons/warning.svg';

type Variant = 'error' | 'info' | 'success';

const mapIcons: Record<Variant, React.ComponentType> = {
  error: WarningIcon,
  info: InfoCircledIcon,
  success: CheckCircledIcon,
};

export interface AlertProps {
  children: React.ReactNode;

  className?: string;
  onClose?: () => void;
  variant?: Variant;
}

export const Alert = React.forwardRef<HTMLDivElement, AlertProps>(
  (props, ref): JSX.Element => {
    const { children, className, onClose, variant = 'info' } = props;

    const isClosable = Boolean(onClose);

    const [isHovered, setIsHovered] = React.useState(false);
    const handleEnter = () => setIsHovered(true);
    const handleLeave = () => setIsHovered(false);

    return (
      <Root
        className={className}
        isClosable={isClosable}
        ref={ref}
        variant={variant}
      >
        <Icon as={mapIcons[variant]} role="img" />

        <div>
          <Content>
            {typeof children === 'string' ? (
              <Markdown>{children}</Markdown>
            ) : (
              <div>{children}</div>
            )}
          </Content>
        </div>

        {onClose ? (
          <CloseButton
            aria-label="Close"
            onClick={onClose}
            onMouseEnter={handleEnter}
            onMouseLeave={handleLeave}
            title="Close"
          >
            <CircleIcon isHovered={isHovered} />

            <CloseIcon />
          </CloseButton>
        ) : null}
      </Root>
    );
  },
);
