import * as React from 'react';
import { Helmet } from 'react-helmet';

interface PageProps {
  children: React.ReactNode;
  title: string;
}

export const Page = (props: PageProps): JSX.Element => {
  const { children, title } = props;

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      {children}
    </>
  );
};
