import { css } from '@emotion/css';
import styled from '@emotion/styled';

import { Link } from '~/components/Link';

export const root = css`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    font: inherit;
    font-weight: 600;
    margin: 0 0 6px;
  }

  & p {
    margin: 0 0 6px;
  }

  & ul {
    margin: 0 0 6px;
    padding: 0 0 0 24px;
  }

  & li {
    margin: 0;
    padding: 0;
  }
`;

export const StyledLink = styled(Link)`
  color: ${(props) => props.theme.palette.brand.white};
  text-decoration: underline;

  &:hover {
    color: ${(props) => props.theme.palette.brand.white};
  }
`;
