import * as React from 'react';

import { Spinner } from '~/components/Spinner';

export const loadable = (
  importFunction: () => Promise<{ default: React.ComponentType }>,
  { fallback = <Spinner /> } = { fallback: null },
): React.FC => {
  const LazyComponent = React.lazy(importFunction);

  return (props) => (
    <React.Suspense fallback={fallback}>
      <LazyComponent {...props} />
    </React.Suspense>
  );
};
