import * as React from 'react';

import type { UserContext } from './types';
import { userContext } from './userContext';

type UseUserHook = () => UserContext;

export const useUser: UseUserHook = () => {
  return React.useContext(userContext);
};
