type ColorHEX = string;

type CSSString = string;

export interface Theme {
  palette: {
    additional: {
      link: ColorHEX;
      pastelBlue: ColorHEX;
      pastelCyan: ColorHEX;
      pastelMint: ColorHEX;
      pastelOrange: ColorHEX;
      pastelPurple: ColorHEX;
      pastelRed: ColorHEX;
      pastelYellow: ColorHEX;
      textOrange: ColorHEX;
    };
    brand: {
      black: ColorHEX;
      blue: ColorHEX;
      coin: ColorHEX;
      grey: ColorHEX;
      white: ColorHEX;
    };
    contrast: {
      gray100: ColorHEX;
      gray200: ColorHEX;
      gray300: ColorHEX;
      gray400: ColorHEX;
      gray500: ColorHEX;
      gray600: ColorHEX;
      gray700: ColorHEX;
      gray800: ColorHEX;
      gray900: ColorHEX;
      gray1000: ColorHEX;
    };
    system: {
      alertRed: ColorHEX;
      infoPurple: ColorHEX;
      successGreen: ColorHEX;
      warningOrange: ColorHEX;
      warningYellow: ColorHEX;
    };
  };

  typography: {
    fontFamily: string;

    body1: CSSString;
    body2: CSSString;
    caption1: CSSString;
    caption2: CSSString;
    title1: CSSString;
    title2: CSSString;
    title3: CSSString;
  };
}

export const theme: Theme = {
  palette: {
    additional: {
      link: '#4794dc',
      pastelBlue: '#e6f2ff',
      pastelCyan: '#ebfaff',
      pastelMint: '#e8f8ea',
      pastelOrange: '#ffecde',
      pastelPurple: '#f6ebff',
      pastelRed: '#ffebeb',
      pastelYellow: '#fff8d6',
      textOrange: '#dc8647',
    },
    brand: {
      black: '#1f2024',
      blue: '#6cb2f3',
      coin: '#f5b85e',
      grey: '#f7f7f7',
      white: '#fff',
    },
    contrast: {
      gray100: '#f6f8fa',
      gray200: '#eff2f5',
      gray300: '#e3e6ea',
      gray400: '#d6d9dc',
      gray500: '#c8ccd1',
      gray600: '#b1b7bd',
      gray700: '#a5abb1',
      gray800: '#959ba1',
      gray900: '#858b93',
      gray1000: '#686c71',
    },
    system: {
      alertRed: '#ec7773',
      infoPurple: '#96a3ef',
      successGreen: '#addea5',
      warningOrange: '#f9d7a3',
      warningYellow: '#fbed94',
    },
  },

  typography: {
    fontFamily: 'Inter, sans-serif',

    body1: `
      font-size: 16px;
      line-height: 20px;
    `,
    body2: `
      font-size: 14px;
      line-height: 18px;
    `,
    caption1: `
      font-size: 12px;
      line-height: 16px;
    `,
    caption2: `
      font-size: 10px;
      line-height: 12px;
    `,
    title1: `
      font-size: 28px;
      line-height: 34px;
    `,
    title2: `
      font-size: 24px;
      line-height: 28px;
    `,
    title3: `
      font-size: 20px;
      line-height: 24px;
    `,
  },
};
