import * as React from 'react';

import { Root } from './ui';

export type ButtonBaseProps = React.ButtonHTMLAttributes<HTMLButtonElement>;

export const ButtonBase = React.forwardRef<HTMLButtonElement, ButtonBaseProps>(
  (props, ref): JSX.Element => {
    const { children, type = 'button', ...restProps } = props;

    return (
      <Root {...restProps} ref={ref} type={type}>
        {children}
      </Root>
    );
  },
);
