import type { Token } from '../../types';

import type { TelegramUserData } from '~/types/TelegramUserData';
import { request } from '~/utils/request';

type GetTokenMethod = (payload: {
  authDate: TelegramUserData['auth_date'];
  firstName: TelegramUserData['first_name'];
  hash: TelegramUserData['hash'];
  id: TelegramUserData['id'];
  lastName: TelegramUserData['last_name'];
  photoUrl: TelegramUserData['photo_url'];
  username: TelegramUserData['username'];
}) => Promise<Token>;

/**
 * Получает актуальный JWT-токен.
 */
export const getToken: GetTokenMethod = async (payload) => {
  const { authDate, firstName, hash, id, lastName, photoUrl, username } =
    payload;

  const {
    data: { token },
  }: { data: { token: Token } } = await request.post('/token', {
    auth_date: authDate,
    first_name: firstName,
    hash,
    id,
    last_name: lastName,
    photo_url: photoUrl,
    username,
  });

  return token;
};
