import * as React from 'react';

import {
  Container,
  Content,
  ContentRoot,
  Footer,
  Header,
  Logo,
  Root,
  Stamp,
} from './ui';

import { ButtonGhost } from '~/components/ButtonGhost';
import { Link } from '~/components/Link';
import { URL_FAQ_EN } from '~/constants';
import { ReactComponent as FlagGb } from '~/images/flags/gb.svg';
import { ReactComponent as ArrowLeftIcon } from '~/ui/icons/chevron-left.svg';
import { createTelegramLink } from '~/utils/createTelegramLink';

const BOT_URL: string = createTelegramLink(import.meta.env.VITE_BOT_USERNAME);
const SUPPORT_BOT_URL = createTelegramLink(
  import.meta.env.VITE_SUPPORT_BOT_USERNAME,
);

interface LayoutFixedProps {
  children: React.ReactNode;
}

export const LayoutFixed = (props: LayoutFixedProps): JSX.Element => {
  const { children } = props;

  return (
    <Root>
      <Container>
        <Header>
          <Logo aria-label="My Donate.bot" />

          <ButtonGhost href="mailto:support@donate.bot">
            Connect to Donate
          </ButtonGhost>
        </Header>

        <ContentRoot>
          <Content>{children}</Content>
        </ContentRoot>

        <Stamp>
          Something went wrong?
          <br />
          You can contact{' '}
          <Link href={SUPPORT_BOT_URL} target="_blank">
            support
          </Link>{' '}
          or read about{' '}
          <Link href={URL_FAQ_EN} target="_blank">
            bot features
          </Link>
        </Stamp>

        <Footer>
          <ButtonGhost
            href={BOT_URL}
            startIcon={<ArrowLeftIcon />}
            target="_blank"
            variant="gray"
          >
            Back to the bot
          </ButtonGhost>

          <FlagGb height={20} width={20} />
        </Footer>
      </Container>
    </Root>
  );
};
