import { useDidMount } from '@bank131/utils';
import { AxiosError } from 'axios';
import * as React from 'react';

import { ErrorAuthorHasMoved } from './errors/ErrorAuthorHasMoved';
import { ErrorNotAuthor } from './errors/ErrorNotAuthor';
import type { SignInAction, SignOutAction } from './types';
import { userContext } from './userContext';

import { usePersistentState } from '~/hooks/usePersistentState';
import { checkToken, getToken, logout, User } from '~/models/User';

const KEY_OF_USER = 'containers/UserProvider::user';

interface UserProviderProps {
  children: React.ReactNode;
}

export const UserProvider = (props: UserProviderProps): JSX.Element => {
  const { children } = props;

  const [error, setError] = React.useState<unknown>(null);

  const [isInitialized, setIsInitialized] = React.useState(false);

  const [user, setUser] = usePersistentState<User | null>(KEY_OF_USER, null);

  const signIn = React.useCallback<SignInAction>(
    async (payload) => {
      const { firstName, id, lastName, photoUrl, username } = payload;
      const telegramUser = {
        firstName,
        id,
        lastName,
        photoUrl,
        username,
      };

      try {
        const token = await getToken(payload);

        const nextUser = {
          telegramUser,
          token,
        };

        setUser(nextUser);

        return nextUser;
      } catch (localError) {
        if (
          localError instanceof AxiosError &&
          localError.response?.status === 400
        ) {
          const {
            response: {
              data: { code },
            },
          } = localError;

          if (code === ErrorNotAuthor.CODE) {
            setError(new ErrorNotAuthor(telegramUser));

            return;
          }

          if (code === ErrorAuthorHasMoved.CODE) {
            setError(new ErrorAuthorHasMoved(telegramUser));

            return;
          }
        }

        setError(localError);
      }
    },
    [setUser],
  );

  const signOut = React.useCallback<SignOutAction>(async () => {
    if (user?.token) {
      try {
        await logout(user.token);
        // eslint-disable-next-line no-empty
      } catch {}
    }

    setUser(null);
  }, [setUser, user?.token]);

  const resetError = React.useCallback(() => {
    setError(null);
  }, []);

  useDidMount(async () => {
    try {
      if (!user?.token) {
        return;
      }

      const isValidToken = await checkToken(user.token);

      if (!isValidToken) {
        await signOut();
      }
    } catch (localError) {
      setError(localError);
    } finally {
      setIsInitialized(true);
    }
  });

  return (
    <userContext.Provider
      value={{
        actions: {
          resetError,
          signIn,
          signOut,
        },
        error,
        isInitialized,
        user,
      }}
    >
      {children}
    </userContext.Provider>
  );
};
