import { createPortal } from 'react-dom';

import type { NotificationType } from './types';
import { Notification, Root } from './ui';

interface NotificationsListProps {
  close: (id: NotificationType['id']) => void;
  root: HTMLElement;
  list: NotificationType[];
}

export const NotificationsList = (
  props: NotificationsListProps,
): JSX.Element => {
  const { close, list, root } = props;

  return createPortal(
    <Root>
      {list.map(({ content, id, variant }) => {
        const handleClose = () => close(id);

        return (
          <Notification key={id} onClose={handleClose} variant={variant}>
            {content}
          </Notification>
        );
      })}
    </Root>,
    root,
  );
};
