import { Helmet } from 'react-helmet';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';

import { ErrorBoundary } from '~/components/ErrorBoundary';
import { NotificationProvider } from '~/components/Notification';
import {
  DashboardPage,
  DEFAULT_URL as DASHBOARD_DEFAULT_URL,
  ROUTE as DASHBOARD_ROUTE,
} from '~/containers/DashboardPage';
import { LoginPage, ROUTE as LOGIN_ROUTE } from '~/containers/LoginPage';
import {
  NotFoundPage,
  ROUTE as NOT_FOUND_ROUTE,
} from '~/containers/NotFoundPage';
import { UserProvider, PrivateRoute } from '~/containers/UserProvider';
import { ThemeProvider } from '~/ui/theme';

export const App = (): JSX.Element => {
  return (
    <ThemeProvider>
      <Helmet titleTemplate="%s | Donate" />

      <ErrorBoundary>
        <NotificationProvider>
          <UserProvider>
            <Router>
              <Switch>
                <Route path={LOGIN_ROUTE} component={LoginPage} />

                <PrivateRoute>
                  <Switch>
                    <Redirect exact from="/" to={DASHBOARD_DEFAULT_URL} />

                    <Route component={DashboardPage} path={DASHBOARD_ROUTE} />

                    <Route component={NotFoundPage} path={NOT_FOUND_ROUTE} />
                  </Switch>
                </PrivateRoute>
              </Switch>
            </Router>
          </UserProvider>
        </NotificationProvider>
      </ErrorBoundary>
    </ThemeProvider>
  );
};
