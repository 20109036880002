import * as React from 'react';

type UsePersistentStateHook = <State>(
  key: string,
  initialState?: State,
) => [State, (nextState: State | ((currentState?: State) => State)) => void];

export const usePersistentState: UsePersistentStateHook = (
  key,
  initialState,
) => {
  const [state, setState] = React.useState(() => {
    const cachedState = localStorage.getItem(key);

    if (cachedState) {
      return JSON.parse(cachedState);
    }

    return initialState;
  });

  React.useEffect(() => {
    localStorage.setItem(key, JSON.stringify(state));
  }, [key, state]);

  return [state, setState];
};
