import { AxiosInstance, AxiosRequestConfig } from 'axios';

import { createRequest } from './createRequest';
import type { Options } from './types';

import { mergeDeepRight } from '~/utils/mergeDeepRight';

type SignedRequestCreator = (
  token: string,
  config?: AxiosRequestConfig,
  options?: Options,
) => AxiosInstance;

export const createSignedRequest: SignedRequestCreator = (
  token,
  config = {},
  options = {},
) =>
  createRequest(
    mergeDeepRight(config, {
      headers: {
        'X-CABINET-TOKEN': token,
      },
    }),
    options,
  );
