import { AxiosRequestConfig } from 'axios';
import qs from 'qs';

import { Options } from './types';

export const DEFAULT_CONFIG: AxiosRequestConfig = {
  baseURL: import.meta.env.VITE_API_CABINET_BASE_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  paramsSerializer: {
    serialize: (params) => {
      return qs.stringify(params, {
        arrayFormat: 'brackets',
        skipNulls: true,
      });
    },
  },
  responseType: 'json',
  timeout: 60000,
  withCredentials: false,
};

export const DEFAULT_OPTIONS: Options = {
  onError: (error: unknown) => Promise.reject(error),
};
