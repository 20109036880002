import type { User } from '~/models/User';

export class ErrorNotAuthor extends Error {
  static CODE = 'is_not_author';

  public telegramUser: User['telegramUser'];

  constructor(telegramUser: User['telegramUser']) {
    super('This telegram user is not the author');

    this.telegramUser = telegramUser;
  }
}
