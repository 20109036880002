import * as React from 'react';

import { Content, Icon, IconBordure, Root, Title } from './ui';

export interface ResultProps {
  children: React.ReactNode;

  action?: React.ReactNode;
  className?: string;
  icon?: React.ElementType;
  title?: React.ReactNode;
  type?: 'error' | 'info';
}

export const Result = (props: ResultProps): JSX.Element => {
  const {
    action = null,
    children,
    className,
    icon,
    title,
    type = 'info',
  } = props;

  const titleId = React.useId();
  const descriptionId = React.useId();
  const labelledBy = React.useMemo(() => {
    if (title) {
      return titleId;
    }

    if (children) {
      return descriptionId;
    }
  }, [children, descriptionId, title, titleId]);

  return (
    <Root className={className}>
      {icon ? (
        <IconBordure aria-labelledby={labelledBy} role="img" type={type}>
          <Icon as={icon} />
        </IconBordure>
      ) : null}

      {title ? <Title id={titleId}>{title}</Title> : null}

      {children ? <Content id={descriptionId}>{children}</Content> : null}

      {action}
    </Root>
  );
};
