import styled from '@emotion/styled';

export const Root = styled('a')`
  color: ${(props) => props.theme.palette.additional.link};
  text-decoration: none;
  transition-duration: 0.15s;
  transition-property: color;
  transition-timing-function: ease-in-out;

  &:hover {
    color: ${(props) => props.theme.palette.brand.blue};
  }

  &:active {
    color: ${(props) => props.theme.palette.additional.link};
  }
`;
