import ReactMarkdown from 'react-markdown';

import { root, StyledLink } from './ui';

interface MarkdownProps {
  children: string;
}

export const Markdown = (props: MarkdownProps): JSX.Element => {
  const { children } = props;

  return (
    <ReactMarkdown
      allowedElements={[
        'a',
        'em',
        'h1',
        'h2',
        'h3',
        'h4',
        'h5',
        'h6',
        'li',
        'ol',
        'p',
        'strong',
        'ul',
      ]}
      className={root}
      components={{
        a: (params) => {
          const { node, ...elementProps } = params;

          return <StyledLink {...elementProps} />;
        },
      }}
      unwrapDisallowed
    >
      {children}
    </ReactMarkdown>
  );
};
