import styled from '@emotion/styled';

import type { ResultProps } from './Result';

export const Root = styled('article')`
  color: ${(props) => props.theme.palette.contrast.gray1000};
  ${(props) => props.theme.typography.body2};
  text-align: center;
`;

type IconBordureProps = Required<Pick<ResultProps, 'type'>>;

export const IconBordure = styled('div')<IconBordureProps>`
  display: inline-block;
  width: 80px;
  position: relative;
  margin-bottom: 12px;
  color: ${(props) => {
    return {
      error: '#921d1d',
      info: props.theme.palette.contrast.gray900,
    }[props.type];
  }};

  &::before {
    display: block;
    padding-top: 100%;
    background-color: ${(props) => {
      return {
        error: props.theme.palette.additional.pastelRed,
        info: props.theme.palette.contrast.gray200,
      }[props.type];
    }};
    border-radius: 50%;
    content: '';
  }
`;

export const Icon = styled('svg')`
  width: 48px;
  height: 48px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
`;

export const Title = styled('h1')`
  margin: 0 0 12px;
  color: ${(props) => props.theme.palette.brand.black};
  ${(props) => props.theme.typography.title2};
  font-weight: 700;
`;

export const Content = styled('div')`
  margin: 0 0 24px;
`;
