import { Result, ResultProps } from '~/components/Result';
import { ReactComponent as ErrorIcon } from '~/ui/icons/error.svg';

interface UnknownErrorProps {
  action?: ResultProps['action'];
}

export const UnknownError = (props: UnknownErrorProps): JSX.Element => {
  const { action } = props;

  return (
    <Result action={action} icon={ErrorIcon} title="Error" type="error">
      Something went wrong
    </Result>
  );
};
