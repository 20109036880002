import * as React from 'react';

import { Root } from './ui';

type LinkProps = React.AnchorHTMLAttributes<HTMLAnchorElement>;

export const Link = (props: LinkProps): JSX.Element => {
  const { children, target, ...restProps } = props;

  const rel = target === '_blank' ? 'noopener noreferrer' : undefined;

  return (
    <Root {...restProps} rel={rel} target={target}>
      {children}
    </Root>
  );
};
