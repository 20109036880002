import type { Token } from '../../types';

import { createSignedRequest } from '~/utils/request';

type LogoutMethod = (token: Token) => Promise<void>;

/**
 * Деактивирует JWT-токен.
 */
export const logout: LogoutMethod = async (token) => {
  const signedRequest = createSignedRequest(token);

  await signedRequest.post('/logout');
};
