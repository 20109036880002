import * as R from 'rambda';

/**
 * Возвращает новый объект, полученный в результате глубокого слияния input1 и input2.
 * Аналогичен методу Rambda.mergeDeepRight за исключением того, что метод Rambda теряет элементы input1
 * если те являются функциями.
 *
 * @see https://github.com/selfrefactor/rambda/issues/636
 */
/* eslint-disable @typescript-eslint/no-explicit-any */
export function mergeDeepRight<O extends Record<string, any>>(
  input1: O,
): (input2: O) => O;
export function mergeDeepRight<O extends Record<string, any>>(
  input1: O,
  input2: O,
): O;
export function mergeDeepRight<O extends Record<string, any>>(
  input1: O,
  input2?: O,
): O | ((input2: O) => O) {
  if (!input2) {
    return (inputHolder: O) => mergeDeepRight(input1, inputHolder);
  }

  return R.compose(
    R.reduce((result, key: string) => {
      const value1 = R.prop(key)(input1);
      const value2 = R.prop(key)(input2);

      const setTo = R.set(R.lensProp(key));

      if (R.type(value2) === 'Object' && R.type(value1) === 'Object') {
        return setTo(mergeDeepRight(value1, value2), result);
      } else {
        return setTo(value2, result);
      }
    }, input1),
    R.keys,
  )(input2);
}
