import { css } from '@emotion/react';

export const globalStyles = css`
  html {
    display: grid;
    min-height: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    overflow-y: auto;
  }

  body {
    display: grid;
    width: 100%;
    min-height: 0;
    margin: 0;
    padding: 0;
    background-color: #fff;
    font-family: Inter, sans-serif;
    font-weight: 400;
    grid-template-rows: 1fr;
  }

  html,
  body {
    min-width: 0;
    margin: 0;
    padding: 0;
  }

  p {
    margin: 0 0 18px;
  }

  #root {
    display: grid;
    min-width: 0;
    min-height: 0;
  }
`;
