import axios, { AxiosResponse } from 'axios';

import { DEFAULT_CONFIG, DEFAULT_OPTIONS } from './constants';
import { RequestCreator } from './types';

import { mergeDeepRight } from '~/utils/mergeDeepRight';

export const createRequest: RequestCreator = (config = {}, options = {}) => {
  const { onError } = mergeDeepRight(DEFAULT_OPTIONS, options);
  const request = axios.create(mergeDeepRight(DEFAULT_CONFIG, config));

  request.interceptors.response.use(
    (response: AxiosResponse) => response,
    onError,
  );

  return request;
};
