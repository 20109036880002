import { Redirect, Route, RouteProps } from 'react-router-dom';

import { Loader } from '../../ui';
import { useUser } from '../../useUser';

import { LayoutFixed } from '~/components/LayoutFixed';
import { Page } from '~/components/Page';
import { UnknownError } from '~/components/UnknownError';
import { DEFAULT_URL as LOGIN_DEFAULT_URL } from '~/containers/LoginPage';

type PrivateRouteProps = RouteProps;

export const PrivateRoute = (props: PrivateRouteProps): JSX.Element => {
  const { error, isInitialized, user } = useUser();

  if (!isInitialized) {
    return <Loader />;
  }

  if (!user?.token) {
    return <Redirect to={{ pathname: LOGIN_DEFAULT_URL }} />;
  }

  if (error) {
    return (
      <Page title="Error">
        <LayoutFixed>
          <UnknownError />
        </LayoutFixed>
      </Page>
    );
  }

  return <Route {...props} />;
};
