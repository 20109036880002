import styled from '@emotion/styled';

export const Root = styled('button')`
  background-color: transparent;
  border: 0;
  color: inherit;
  font: inherit;
  appearance: none;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }
`;
