import * as React from 'react';

import { LayoutFixed } from '~/components/LayoutFixed';
import { Page } from '~/components/Page';
import { UnknownError } from '~/components/UnknownError';

interface ErrorBoundaryProps {
  children: React.ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

export class ErrorBoundary extends React.PureComponent<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  state = { hasError: false };

  static getDerivedStateFromError(): ErrorBoundaryState {
    return { hasError: true };
  }

  render(): React.ReactNode {
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return (
        <Page title="Error">
          <LayoutFixed>
            <UnknownError />
          </LayoutFixed>
        </Page>
      );
    }

    return children;
  }
}
