import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

import { ReactComponent as SpinSvg } from './images/spin.svg';

export const Root = styled('div')`
  width: 48px;
  position: relative;
  background-color: ${({ theme }) => theme.palette.brand.white};
  border-radius: 50%;
  box-shadow: 0 12px 32px rgba(7, 52, 102, 0.25);

  &::before {
    display: block;
    padding-top: 100%;
    content: '';
  }
`;

const spin = keyframes`
  0% {
    transform: rotateZ(0) translate3d(-50%, -50%, 0);
  }

  100% {
    transform: rotateZ(360deg) translate3d(-50%, -50%, 0);
  }
`;

export const Spin = styled(SpinSvg)`
  position: absolute;
  top: 50%;
  left: 50%;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-name: ${spin};
  animation-timing-function: linear;
  transform-origin: left top;
`;
